<template>
  <div class="online-store-detail">
    <el-scrollbar :native="false" class="store-set-scroll">
      <div class="store-set-table">
        <div class="set-table-header">
          <div class="table-header-item"><span>考试内容</span></div>
          <div class="table-header-item"><span>注册填写问题</span></div>
          <div class="table-header-item"><span>学生提交答案</span></div>
          <div class="table-header-item" v-if="isShow"><span>正确答案</span></div>
          <div class="table-header-item"><span>得分（{{stuTotalScore}}分）</span></div>
        </div>
        <template v-if="detailData.list.length">
          <div class="set-table-body">
            <div class="table-body-item"><span class="text-overflow">网店注册</span></div>
            <div class="table-body-item"><span class="text-overflow">{{detailData.list[0].name}}</span></div>
            <div class="table-body-item"><span class="text-overflow">{{detailData.list[0].stu_answer}}</span></div>
            <div class="table-body-item" v-if="isShow"><span class="text-overflow">{{detailData.list[0].answer}}</span></div>
            <div class="table-body-item"><span class="text-overflow">{{detailData.list[0].score}}</span></div>
          </div>
          <div class="set-table-body" v-if="index !== 0" v-for="(item,index) in detailData.list">
            <div class="table-body-item"><span></span></div>
            <div class="table-body-item"><span class="text-overflow">{{item.name}}</span></div>
            <div class="table-body-item" v-if="item.name === '营业执照电子版' || item.name === '法定证件电子版' || item.name === '税务登记证电子版' || item.name === '一般纳税人资格证电子版' || item.name === '店铺logo'">
              <span style="color: #2DC079;cursor: pointer" @click="openImgShow(item.stu_answer)">查看详情</span>
            </div>
            <div class="table-body-item" v-else><span class="text-overflow">{{item.stu_answer}}</span></div>
            <div class="table-body-item" v-if="isShow"><span class="text-overflow">{{item.answer}}</span></div>
            <div class="table-body-item"><span class="text-overflow">{{item.score}}</span></div>
          </div>
        </template>
        <div v-else style="text-align: center;line-height: 60px">暂无数据</div>
      </div>
      <div class="img-mask-layer" v-if="imgShow">
        <div class="img-box">
          <img :src="imgSrc[imgIndex]" v-if="Array.isArray(imgSrc)" alt="">
          <div style="padding: 50px; background: #fff" v-else-if="!imgSrc">未提交</div>
          <img :src="imgSrc" v-else alt="">
          <i class="iconfont" @click="imgShow = false">&#xe659;</i>
        </div>
        <div class="last-img" v-if="Array.isArray(imgSrc)" @click="cutImg(0)"><i class="iconfont">&#xe680;</i></div>
        <div class="next-img" v-if="Array.isArray(imgSrc)" @click="cutImg(1)"><i class="iconfont">&#xe602;</i></div>
      </div>
    </el-scrollbar>

  </div>
</template>

<script>
export default {
  name: "StoreSetPageDetail",
  props:['detailData'],
  computed:{
    stuTotalScore(){
      let num = 0;
      if(this.detailData.list.length){
        this.detailData.list.forEach(item=>{
          num += Number(item.score)
        })
      }
      return num.toFixed(2)
    },
    isShow() {
        return [3,6].includes(parseInt(this.role))
    }
  },
  data(){
    return {
      imgShow:false,
      imgSrc:null,
      imgIndex:0,
      role:Number(localStorage.getItem('role')) || null,
    }
  },
  methods:{
    openImgShow(url){
      this.imgSrc = url;
      this.imgShow = true
    },
    cutImg(num){
      this.imgIndex = num
    }
  }
}
</script>

<style scoped lang="scss">
.online-store-detail{
  height: 100%;
  display: flex;
  flex-direction: column;
  .store-set-scroll{
    display:flex;
    flex:1;
    height:1%;
    flex-direction: column;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
      .el-scrollbar__view{
        padding: 20px 0;
        box-sizing: border-box;
      }
      .store-set-table{
        display: flex;
        flex-direction: column;
        border: 1px solid #EEEEEE;
        .set-table-header{
          height: 60px;
          display: flex;
          background: #F6F6F6;
          .table-header-item{
            flex: 1;
            width: 1%;
            span{
              color: #666666;
              font-size: 16px;
              line-height: 60px;
            }
            &:first-of-type{
              span{
                margin-left: 58px;
              }
            }
            &:last-of-type{
              text-align: center;
            }
          }
        }
        .set-table-body{
          display: flex;
          border-top: 1px solid #E5E5E5;
          height: 50px;
          .table-body-item{
            flex: 1;
            display: flex;
            width: 1%;
            span{
              color: #333;
              font-size: 16px;
              line-height: 50px;
            }
            &:first-of-type{
              span{
                margin-left: 58px;
              }
            }
            &:last-of-type{
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
.img-mask-layer{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top:0;
  background: rgba(0,0,0,0.3);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  .img-box{
    position: relative;
    img{
      max-width: 500px;
    }
    i{
      position: absolute;
      font-size: 50px;
      line-height: 1;
      color: #FFFFFF;
      right: -100px;
      top: -66px;
      cursor: pointer;
    }
  }
  .last-img, .next-img{
    position: absolute;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    &.last-img{
      left: 30px;
    }
    &.next-img{
      right: 30px;
    }
    i{
      line-height: 1;
      font-size: 50px;
      color: #B2B2B2;
    }
  }
}
</style>